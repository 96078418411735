h1, h2, h3, p, div, select {
  color: #FFF;
  font-family: "Montserrat";
}

h1 {
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 2px;
}
h1 sup {
  font-size: 16px;
}

p, div {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
}