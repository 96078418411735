.conversion {
  height: 200px;
}

.rack {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-height: calc(100vh - 88px);
  min-height: 100px;
  overflow: scroll;
}

.can {
  background-image: url('./images/can.png');
  background-position: bottom;
  background-size: auto 100px;
  background-repeat: no-repeat;
  width: 48px;
  height: 100px;
}
