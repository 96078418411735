html, body {
  margin: 0;
  padding: 0;
}

html {
  background: url("./images/mountains.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  text-align: center;
}